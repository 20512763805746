import * as React from 'react';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import withRoot from './modules/withRoot';
import Box from '@mui/material/Box';

function Location() {
  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
      <Box sx={{ mt: 4}}>
        <React.Fragment>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            찾아오시는 길
          </Typography>
          <Typography>
            <br/>
          </Typography>
          <Typography variant="body3" align="center">
          <br />
          주소: 서울 서초구 방배로 196 304호
          <br />
          <br />
          </Typography>
          <img 
            src="location2.jpeg" 
            alt="약도" 
            style={{
              width: '100%',  /* 이미지가 버튼 너비에 맞춰지도록 설정 */
              height: '100%',  /* 이미지가 버튼 높이에 맞춰지도록 설정 */
              objectFit: 'contain'  /* 이미지 비율 유지 */
            }}
          />
          <img 
            src="location3.jpeg" 
            alt="약도" 
            style={{
              width: '100%',  /* 이미지가 버튼 너비에 맞춰지도록 설정 */
              height: '100%',  /* 이미지가 버튼 높이에 맞춰지도록 설정 */
              objectFit: 'contain'  /* 이미지 비율 유지 */
            }}
          />

          
          
        </React.Fragment>
        </Box>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Location);
