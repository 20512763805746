import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 2,
};

function AppAppBar() {
  return (
    <div>
      <AppBar 
        position="fixed"
        sx={{ backgroundColor: '#f5f5f5' }} // 밝은 색상 사용
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="/"
              sx={{ ...rightLink, color: 'black', mr: 2 }}
            >
              {'Home'}
            </Link>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="/Intro"
              sx={{ ...rightLink, color: 'black' }}
            >
              {'회사소개'}
            </Link>
            <Link
              variant="h6"
              underline="none"
              href="/Location"
              sx={{ ...rightLink, color: 'secondary.main' }}
            >
              {'찾아오시는 길'}
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;