import * as React from 'react';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import withRoot from './modules/withRoot';
import Box from '@mui/material/Box';

function Location() {
  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
      <Box sx={{ mt: 4}}>
        <React.Fragment>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            서비스 요금안내 
          </Typography>
          <Typography>
            <br/>
          </Typography>
          <Typography variant="body5" align="center">
          급여는 가족 수, 평수 등을 고려해 적절한 급여를 책정함.
          <br />
          <br />
          <Typography variant="h6" align="left">
          입주 도우미: 협의 후 결정<br />
          출퇴근 도우미: 협의 후 결정<br />
          소 개 비: 2개월 임금의 10%<br />
          <br />
          <br />
          </Typography>
          <Typography variant="h6" align="left">
          가사도우미의 일하는 시간
          </Typography>
          <br />
          하루 전일제: 오전9시 ~ 오후5시<br />
          오전 시간제: 오전9시 ~ 오후1시<br />
          오후 시간제: 오후1시(2시) ~ 오후5시(6시)<br />
          추가시: 1시간당 협의 금액<br />
          청소도우미, 산모도우미, 베이비시터 등도 요금 동일함.
          </Typography>
          
        </React.Fragment>
        </Box>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Location);
