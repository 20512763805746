import * as React from 'react';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import withRoot from './modules/withRoot';
import Box from '@mui/material/Box';

function Location() {
  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
      <Box>
        <React.Fragment>
          <Typography>
            <br/>
          </Typography>
          <Typography variant="h6" align="center" color='secondary.main'>
          "명성도우미의 자격요건"
          <br />
          <br/>
          </Typography>
          <Typography variant="body5" align="left">
          명성도우미에서 지향하는 가사전문 맞춤 서비스를 할 수 있는 분<br />
          밝고 센스 충만한 30대~40대 여성<br />
          <br />
          명성도우미는 여성만을 취업알선하며 가정집에 맡은바 책임을 다하는 도우미만을 파견합니다.
          정식 허가업체이므로 서류, 교육 등 철저한 정신교육 받은, 도우미파견 고객님들의 입에서 입으로 전하는 소문난 
          가사도우미와 예절바른 교육, 항상 깨끗하고 단정한 도우미만을 파견해 드릴 것을 약속드립니다.
          <br />
          <br />
          <br />
          </Typography>
          <Typography variant="h6" align="center" color='secondary.main'>
          "명성도우미는 달라요"
          <br/>
          <br />
          </Typography>
          <Typography variant="body5" align="left">
          신원이 확실하며 책임감이 충만한 도우미들로 구성되어 있습니다.<br />
          아이들을 진심으로 사랑하고 인성이 착한 도우미들로 구성되어 있습니다.<br />
          건전한 가정과 어려운 일도 마다치 않는 도우미들로 구성되어 있습니다.<br />
          <br />
          <br />
          </Typography>
          <Typography variant="h6" align="center" color='secondary.main'>
          "명성도우미의 교육프로그램"
          <br/>
          <br />
          </Typography>
          <Typography variant="body5" align="left">
          자체실무교육<br />
          가사도우미역할 및 교육<br />
          요리할 때 주의할 점<br />
          아이들 관리교육<br />
          실버도우미 주의점<br />
          간병인 교육<br />
          주방의 위생적인 청소요령<br />
          가스렌지 청소<br />
          씽크대 청소<br />
          냉장고 소독 및 청소<br />
          행주 삶기, 욕실청소와 정리 등 방과 거실 청소<br />
          가전제품 청소시 주의할 점<br />
          가구 및 청소하기 등 빨래할 때 주의점<br />
          음식쓰레기 재활용 분리수거요령<br />
          세탁기와 손빨래 주의할 점 등<br />
          </Typography>
        </React.Fragment>
        </Box>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Location);
