import * as React from 'react';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import withRoot from './modules/withRoot';
import Box from '@mui/material/Box';

function Intro() {
  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
      <Box sx={{ mt: 4}}>
        <React.Fragment>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            인사말
          </Typography>
          
          <Typography variant="body3" align="center">
          <br />
          <br />
          젊고 정직한 기업 〈명성〉은 고객님의 가정을 내 가정처럼 생각하며 최선을 다하겠습니다. <br /><br />
          수많은 가사도우미 및 베이비시터 회사가 있지만, 진심과 신뢰로 고객의 이익을 지키는 회사는 드뭅니다.<br />
          명성은 어려운 상황에서도 최선을 다하는 진실된 자세로 많은 고객들의 믿음을 얻고 있습니다. 맞벌이 가정의 증가로 인한 어려움을 덜어드리기 위해, 명성은 체계적인 교육과 서비스를 통해 고객님께 편안함과 진심을 전달하겠습니다.<br /> <br />
          언제나 최선을 다하는 명성을 믿고 맡겨주십시오.
          </Typography>
        </React.Fragment>
        
        <Typography variant="h6" align="right" sx={{ mt: 4 }}>
          감사합니다.<br />
          명성 임직원 일동
        </Typography>
        </Box>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Intro);
