import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

const images = ['main2.png', 'main3.png', 'main4.png'];

export default function ProductHero() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // 3000ms = 3 seconds

    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  const backgroundImage = images[currentImageIndex];

  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h5">
        30여년 노하우의 가정집 전문
      </Typography>
      <Typography color="inherit" align="center" variant="h4" marked="center">
        명성도우미 
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
      </Typography>
      <a href="https://pf.kakao.com/_xkMgAn" target="_blank" rel="noopener noreferrer">
        <button
          style={{
            backgroundColor: 'transparent',
            color: '#000',
            border: 'none',
            borderRadius: '5px',
            padding: '0.0em',  /* 버튼 내부 여백을 상대적으로 설정 */
          }}
        >
          <img 
            src="kakao3.png" 
            alt="Kakao Icon" 
            style={{
              width: '40%',  /* 이미지가 버튼 너비에 맞춰지도록 설정 */
              height: '40%',  /* 이미지가 버튼 높이에 맞춰지도록 설정 */
              objectFit: 'contain'  /* 이미지 비율 유지 */
            }}
          />
        </button>
      </a>
      <Typography variant="body2" color="inherit">
        카카오톡 채널로 실시간 상담 하러가기
      </Typography>
    </ProductHeroLayout>
  );
}