import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',   // 배경색을 검정색으로 설정
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
    height: 100,
  },
  '&:hover': {
    zIndex: 1,
  },
  '&:hover .imageBackdrop': {
    opacity: 0.15,
  },
  '&:hover .imageMarked': {
    opacity: 0,
  },
  '&:hover .imageTitle': {
    border: '4px solid currentColor',
  },
  '& .imageTitle': {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const images = [
  {
    url: 'category-1.png',
    title: '가정부 도우미',
    description: '일반 가사도우미와 일의 영역이 같으나 차이점은 고객님의 가정에서 가족처럼 지내며 가정일을 돕는 도우미를 말합니다.<br /><br />음식 만들기<br />아이 돌보기<br />어른과 환자 돌보기<br />설거지하기<br />빨래와 다림질하기<br />쓰레기 재활용 구분해서 처리하기<br />기본청소, 대청소, 화장실 청소<br />애완견 돌보기 등',
    width: '50%',
  },
  {
    url: 'category-2.png',
    title: '가사 도우미',
    description: '실버도우미, 산모도우미, 간병인, 요리사 등<br /><br />집안 대청소가 필요한 경우<br />맞벌이 부부를 위해 정기 청소가 필요한 경우<br />주부 혼자서 감당하기 어려운 가정 청소업무<br />집뜰이 행사, 생일잔치 등의 행사<br />가사 및 육아를 병행하기 힘든 신혼주부',
    width: '50%',
  },
  {
    url: 'category-3.png',
    title: '가사 베이비시터',
    description: '아이들을 돌보는데 그치지 않고 부모님을 대신하여 아이들에게 사랑을 전달할 수 있는 책임감있는 도우미가 파견됩니다.<br /><br />우유 먹이기, 기저귀 갈기, 잠재우기, 목용하기, 놀이하기등 아이와 관련된 일하기<br />(우유병 세척/소독, 침구 정리, 아이방 정리, 기저귀 애벌빨래 등)<br />회원이 원하는 놀이하기, 밥 또는 간식 먹이기, 학원 보내기 또는 픽업하기, 학습지 봐주기 등 주로 기본관리 및 놀이를 중심으로 돌보기<br />초등학생의 생활관리(숙제 및 과제물 봐주기) 및 간단한 공부 봐주기<br />간단한 집안 청소와 가족의 반찬 만들기-아이가 안전한 상태에 있을 때 가능합니다.',
    width: '33.3%',
  },
  {
    url: 'category-4.png',
    title: '청소 도우미',
    description: '편안하고 깔끔한 생활을 위한 청소 도우미를 파견해드립니다.<br /><br />새로 이사한 집의 입주 전 청소<br />이사 전후의 집안 대청소<br />사무실 대청소<br />원룸빌라 등 청소<br /><br />*청소 분량에 따라 다소 인건비가 다를 수 있습니다.',
    width: '33.3%',
  },
  {
    url: 'category-5.png',
    title: '산모 도우미',
    description: '개인사정으로 조리원을 이용하지 못하실 경우 도우미가 직접 방문하여 산모와 신생아를 내 아이처럼 도와드리고 집안일을 모두 맡아 도움을 드립니다.<br /><br />신생아 목욕 및 마사지<br />모유 먹이기, 젖병 소독하기<br />산모식사 및 간식 챙겨주기<br />가족 관련 가사일(빨래, 다림질, 청소)',
    width: '33.3%',
  },
];

const ModalDialog = ({ open, onClose, title, description }) => (
  <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
    <Box
      component={Paper}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 4,
        textAlign: 'center', // 텍스트와 버튼을 가운데 정렬
      }}
    >
      <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography
        id="modal-description"
        variant="body1"
        sx={{ mb: 3 }}
        dangerouslySetInnerHTML={{ __html: description }} // HTML 태그를 해석하도록 설정
      />
      <Button onClick={onClose} variant="contained" color="error" sx={{ display: 'inline-block' }}>
        닫기
      </Button>
    </Box>
  </Modal>
);

export default function ProductCategories() {
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <Container component="section" sx={{ mt: 6, mb: 4 }}>
      <Typography variant="h4" marked="center" align="center" component="h2" >
        서비스 종류
      </Typography>
      <Typography variant="h7">
        <br />- 한국인과 교포(중국)가 있고 대한민국 주민등록증을 소지한 교포와 외국인 등록증을 소지한 교포가 있습니다.
        <br />- 24시간 상주하시는 입주형과 시간을 정하여 출퇴근 하시는 출퇴근형이 있습니다.
      </Typography>
      <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap' }}>
        {images.map((image) => (
          <ImageIconButton
            key={image.title}
            style={{
              width: image.width,
            }}
            onClick={() => handleClickOpen(image)}
          >
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: 'cover',
                backgroundPosition: 'center 40%',
                backgroundImage: `url(${image.url})`,
              }}
            />
            <ImageBackdrop className="imageBackdrop" />
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'common.white',
              }}
            >
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className="imageTitle"
              >
                {image.title}
                <div className="imageMarked" />
              </Typography>
            </Box>
          </ImageIconButton>
        ))}
      </Box>

      {/* 모달 다이얼로그 */}
      <ModalDialog
        open={open}
        onClose={handleClose}
        title={selectedImage?.title}
        description={selectedImage?.description}
      />
    </Container>
  );
}