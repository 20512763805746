import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: 'default',
  color: 'secondary.main',
  fontWeight: 'medium',
};

const image = {
  height: 55,
  my: 4,
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 5,
          mb: 5,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
          서비스 절차
        </Typography>
        <div>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Box sx={item}>
                <Box sx={number}>1.</Box>
                <Box
                  component="img"
                  src="process-1.gif"
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  전화/카카오채널 상담
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={item}>
                <Box sx={number}>2.</Box>
                <Box
                  component="img"
                  src="process-2.gif"
                  alt="graph"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  회원가입
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={item}>
                <Box sx={number}>3.</Box>
                <Box
                  component="img"
                  src="process-3.gif"
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  {'회원등록'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={item}>
                <Box sx={number}>4.</Box>
                <Box
                  component="img"
                  src="process-4.gif"
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                {'도우미등록'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid>
            <Grid>
              <Box sx={{mt: 6}} >
                <Typography variant="h6" align="left" sx={{ mb: 2 }}>
                {'30여년 전통의 명성도우미는 예약제와 정회원제도로 운영됩니다.'}
                </Typography>
                <Typography variant="h5" align="left">
                {'서비스의 기본자세'}
                </Typography>
                <Typography variant="h5" align="left">
                {'고객님과의 첫 만남에서 예의 바른 인사와 밝은 표정으로 명성도우미의 서비스 철학이 시작됩니다.'}
                </Typography>
                <Typography variant="h5" align="left">
                {'세심한 서비스로 품격 있는 만남을 제공하며, 항상 자기관리에 힘쓰는 전문가입니다.'}
                </Typography>
                <Typography variant="h5" align="left">
                {'세상에서 가장 따뜻하고 아름다운 만남을 위해, 고객님의 기쁨을 최우선으로 하는 “명성도우미”가 되겠습니다.'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="/Pricing"
          sx={{ mt: 4 }}
        >
          서비스 요금안내
        </Button>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
