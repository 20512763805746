import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Intro from './Intro';
import Location from './Location';
import Pricing from './Pricing';
import Academic from './Academic';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Intro" element={<Intro />} />
        <Route path="/Location" element={<Location />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/Academic" element={<Academic />} />
      </Routes>
    </BrowserRouter>


  );
}

export default App;