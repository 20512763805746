import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';

function ProductCTA() {
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component="section" sx={{ mt: 6, display: 'flex' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              bgcolor: 'warning.main',
              py: 4,
              px: 3,
            }}
          >
            <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400 }}>
              <Typography variant="h4" component="h2" align="center" gutterBottom>
                명성 아카데미
              </Typography>
              <Typography variant="h5" align="center">
                명성도우미만의 교육프로그램 과정
              </Typography>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                href="/Academic"
                sx={{ width: '100%' ,mt: 4}}
              >
                자격요건 및 교육내용
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
        >
          {/* <Box
            sx={{
              position: 'absolute',
              top: -67,
              left: -67,
              right: 0,
              bottom: 0,
              width: '100%',
              background: 'url(/static/themes/onepirate/productCTAImageDots.png)',
            }}
          /> */}
          {/* <Box
            component="img"
            src="https://images.unsplash.com/photo-1527853787696-f7be74f2e39a?auto=format&fit=crop&w=750"
            alt="call to action"
            sx={{
              position: 'absolute',
              top: -28,
              left: -28,
              right: 0,
              bottom: 0,
              width: '100%',
              maxWidth: 600,
            }}
          /> */}
        </Grid>
      </Grid>
      {/* <Snackbar
        open={open}
        closeFunc={handleClose}
        message="We will send you our best offers, once a week."
      /> */}
    </Container>
  );
}

export default ProductCTA;
